import React from 'react';
import File from './File';

const Svg = ({ color = '#27B740' }) => (
  <File>
    <path
      fill={color}
      fillRule="evenodd"
      d="M9.35494 23.3152l-.9666-1.2863h.7452l.5832.8348.61016-.8348h.7128l-.9504 1.2548 1.0692 1.4595h-.7398l-.70736-1.0028-.6858 1.0028h-.7236l1.053-1.428zM11.6556 24.7432h.6156v-3.7484h-.6156v3.7484zM13.5397 23.8718c.018.175.0864.2974.2052.3674.1188.07.261.105.4266.105.0576 0 .123-.004.1968-.0134.0738-.0087.1434-.0251.2082-.0496.0648-.0245.1176-.06.1596-.1073.0414-.0472.06-.1097.0564-.1866-.0036-.077-.0324-.14-.0864-.189-.054-.049-.1236-.0881-.2076-.1179-.0846-.0303-.1812-.0554-.2892-.0764-.108-.021-.2178-.0437-.3294-.0682-.1152-.0245-.2262-.0543-.3318-.0893-.1068-.035-.2022-.0822-.2862-.1417-.0846-.0595-.1524-.1354-.2028-.2281-.0504-.0928-.0756-.2077-.0756-.3442 0-.147.0366-.2707.1104-.3704.0738-.0998.168-.1797.2814-.2415.1134-.0607.2394-.1038.3774-.1283.1386-.0245.2712-.0368.3972-.0368.144 0 .2814.0146.4134.0443.1308.0304.2502.0782.3558.1447.1068.0665.195.1534.2652.2596.0696.1073.114.2357.132.3862h-.6426c-.0288-.1435-.0966-.2398-.2022-.2888-.1068-.049-.228-.0741-.3648-.0741-.0432 0-.0942.0041-.1542.0111-.0594.007-.1146.0198-.1668.0391-.0528.0198-.0966.0472-.1326.0846-.036.0361-.054.0846-.054.1441 0 .0735.0264.133.078.1785.0522.0455.1212.0828.2058.1125.084.0304.1806.0555.2886.0765.108.021.2196.0437.3348.0682.1116.0245.2214.0543.3294.0893.108.035.204.0822.2892.1417.084.0595.1524.1348.2046.2258.0522.091.0786.203.0786.3354 0 .1615-.0378.298-.1134.41-.0756.112-.174.203-.294.273-.1212.07-.255.1208-.4026.1523-.1476.0315-.2934.0472-.4374.0472-.1764 0-.3396-.0192-.4884-.0577-.1494-.0385-.2796-.0974-.3888-.1756-.1098-.0793-.1962-.1773-.2592-.294-.063-.1172-.0966-.2566-.1002-.4176h.6156zM13.8 17.4714h2.4v-2.9166h-2.4v2.9166zm-3 0h2.4v-2.9166h-2.4v2.9166zm-3.00002 0H10.2v-2.9166H7.79998v2.9166zm0-3.5H10.2v-2.9166H7.79998v2.9166zm6.00002 0h2.4v-2.9166h-2.4v2.9166zm-3 0h2.4v-2.9166h-2.4v2.9166zm5.7-3.5H7.49998c-.1662 0-.3.1307-.3.2917v7c0 .1616.1338.2917.3.2917H16.5c.1656 0 .3-.1301.3-.2917v-7c0-.161-.1344-.2917-.3-.2917z"
      clipRule="evenodd"
    />
  </File>
);

export default Svg;
