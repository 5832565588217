import React from 'react';
import File from './File';

const Svg = ({ color = '#795545', ...props }) => (
  <File {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M8.57751 22.0289h.4644v-.8138h.6156v.8138h.55619v.4462h-.55619v1.449c0 .063.003.1173.0078.1628.0054.0455.0186.084.0384.1161.0192.0309.0492.0542.0888.0706.0396.0157.0936.0233.162.0233.0432 0 .08639-.0006.12959-.0023.0432-.0018.0864-.0082.1296-.0181v.462c-.0684.0064-.135.0134-.1998.0204-.06479.007-.13139.0105-.19979.0105-.162 0-.2928-.0146-.3918-.0449-.099-.0298-.1764-.0735-.2322-.1313-.0552-.0571-.0936-.1301-.1134-.2176-.0192-.0875-.0312-.1872-.0348-.2992v-1.6013h-.4644v-.4462zM11.5424 23.3152l-.9666-1.2863h.7452l.5832.8348.6102-.8348h.7128l-.9504 1.2548 1.0692 1.4595h-.7404l-.7068-1.0028-.6858 1.0028h-.7236l1.053-1.428zM13.5451 22.0289h.4644v-.8138h.6162v.8138h.5562v.4462h-.5562v1.449c0 .063.0024.1173.0078.1628s.018.084.0378.1161c.0198.0309.0492.0542.0888.0706.0402.0157.0942.0233.1626.0233.0426 0 .0858-.0006.1296-.0023.0426-.0018.0858-.0082.1296-.0181v.462c-.069.0064-.135.0134-.1998.0204-.0654.007-.1314.0105-.1998.0105-.1626 0-.2928-.0146-.3918-.0449-.099-.0298-.1764-.0735-.2328-.1313-.0552-.0571-.093-.1301-.1128-.2176-.0198-.0875-.0318-.1872-.0354-.2992v-1.6013h-.4644v-.4462zM12.9 11.0549h3.6c.1656 0 .3-.1306.3-.2916 0-.161-.1344-.2917-.3-.2917h-3.6c-.1656 0-.3.1307-.3.2917s.1344.2916.3.2916zM16.5 12.2216h-3.6c-.1656 0-.3.1307-.3.2917s.1344.2916.3.2916h3.6c.1656 0 .3-.1306.3-.2916 0-.161-.1344-.2917-.3-.2917zM16.5 13.9716h-3.6c-.1656 0-.3.1307-.3.2917s.1344.2916.3.2916h3.6c.1656 0 .3-.1306.3-.2916 0-.161-.1344-.2917-.3-.2917zM16.5 15.7216H7.50001c-.1656 0-.3.1307-.3.2917s.1344.2916.3.2916H16.5c.1656 0 .3-.1306.3-.2916 0-.161-.1344-.2917-.3-.2917zM16.5 17.4716H7.50001c-.1656 0-.3.1307-.3.2917s.1344.2916.3.2916H16.5c.1656 0 .3-.1306.3-.2916 0-.161-.1344-.2917-.3-.2917zM7.50001 11.0549h1.5v3.2813c0 .161.1344.2916.3.2916s.3-.1306.3-.2916v-3.2813H11.1c.1656 0 .3-.1306.3-.2916 0-.161-.1344-.2917-.3-.2917H7.50001c-.1656 0-.3.1307-.3.2917s.1344.2916.3.2916z"
      clipRule="evenodd"
    />
  </File>
);

export default Svg;
