import React from 'react';

const Svg = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 16C12.3857 16 16 12.3793 16 7.99581C16 3.62074 12.3774 0 8 0C3.61426 0 0 3.62074 0
      7.99581C0 12.3793 3.62264 16 8 16ZM5.12369 11.4573C4.79665 11.4573 4.54507 11.1975 4.54507
      10.879C4.54507 10.7197 4.60377 10.5773 4.71279 10.4767L7.1782 8.00419L4.71279 5.53169C4.60377
      5.43112 4.54507 5.28863 4.54507 5.12939C4.54507 4.80251 4.79665 4.55946 5.12369
      4.55946C5.28302 4.55946 5.41719 4.61812 5.52621 4.72708L8 7.1912L10.4822 4.72708C10.5996
      4.60136 10.7338 4.54269 10.8847 4.54269C11.2034 4.54269 11.4633 4.80251 11.4633
      5.12101C11.4633 5.28025 11.413 5.40597 11.2872 5.52331L8.81342 8.00419L11.2872
      10.4599C11.3962 10.5773 11.4549 10.7114 11.4549 10.879C11.4549 11.1975 11.2034 11.4573
      10.8763 11.4573C10.7086 11.4573 10.566 11.3903 10.457 11.2813L8 8.81718L5.53459
      11.2813C5.43396 11.3903 5.28302 11.4573 5.12369 11.4573Z"
      fill="#898E9E"
    />
  </svg>
);

export default Svg;
