import React from 'react';

const Svg = ({ bg = '#fff', fg = '#cacaca', children, ...props }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 28"
    fill="none"
    vectorEffect="non-scaling-stroke"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.24993 27.8542c-1.158 0-2.099997-.9263-2.099997-2.065V2.21031c0-1.13809.941997-2.064421 2.099997-2.064421H16.4999c.1614 0 .3114.060667.423.172084l6.7506 6.631917c.114.112.1764.26075.1764.41883V25.7892c0 1.1387-.942 2.065-2.1 2.065H2.24993z"
      fill={bg}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4999.00010681H2.24991C1.00911.00010681-.00009155.99119-.00009155 2.21036V25.7899c0 1.2185 1.00920155 2.2102 2.25000155 2.2102H21.7499c1.2402 0 2.25-.9917 2.25-2.2102V7.36877c0-.196-.0792-.38266-.2196-.5215l-6.75-6.63133c-.141-.1394165-.3312-.21583319-.5304-.21583319zm0 .29162219c.1206 0 .2328.0455.3168.1295l6.75 6.631331c.0864.084.1332.19659.1332.31617V25.7898c0 1.0576-.8748 1.9186-1.95 1.9186H2.24995c-1.0752 0-1.950005-.861-1.950005-1.9186V2.21031c0-1.05758.874805-1.918581 1.950005-1.918581H16.4999z"
      fill={fg}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9999 7.36861c0-.196-.0792-.38267-.2196-.5215l-6.75-6.631338c-.141-.1394164-.3312-.21583304-.5304-.21583304h-.4878V5.63727c0 1.21858 1.0092 2.21025 2.25 2.21025h5.7378v-.47891z"
      fill={fg}
    />
    {children}
  </svg>
);

export default Svg;
